<template>
  <div class="courses-opportunities default-layout-desktop-padding">
    <div class="courses-opportunities__main-container flex-column h-100">
      <div class="flex-column gap-3 h-100">
        <section class="flex-column gap-2" v-if="allCourseList.length">
          <div class="flex-row ai-c">
            <Typography variant="custom" textSize="1.125rem">Courses</Typography>
            <!-- <Icon customPath="icons/status-icons" iconName="complete.svg" iconHeight="18px" iconWidth="18px"/> -->
          </div>
          <div class="courses-opportunities__wrapper grid">
            <CourseCard
              v-for="(course, index) in allCourseList"
              :key="index"
              :course="course"/>
          </div>
        </section>
        <!-- <section class="flex-column" v-if="allHasNoSavedDateCourseList.length">
          <div class="flex-row ai-c">
            <Typography variant="custom" textSize="1.125rem">Date not selected</Typography>
            <Icon customPath="icons/status-icons" iconName="alert.svg" iconHeight="18px" iconWidth="18px"/>
          </div>
          <div class="courses-opportunities__wrapper grid">
            <CourseCard
              v-for="(course, index) in allHasNoSavedDateCourseList"
              :key="index"
              :course="course"/>
          </div>
        </section> -->
        <section class="flex-column" v-else>
          <Typography variant="custom" textSize="1.125rem">No enrolled courses available</Typography>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

// import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CourseCard from '@/modules/courses-children/components/CourseCard.vue';
import courseState from '@/modules/courses-children/constants';
import { courseDetails } from '@/modules/courses-children/data';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';
import { OPPORTUNITY_BILLING_STORE } from '@/store/modules/opportunity-billing';

export default defineComponent({
  components: {
    CourseCard,
    Typography,
    // Icon,
  },
  data() {
    return {
      allCourseList: [],
      //  allHasSavedDateCourseList: [],
      //  allHasNoSavedDateCourseList: [],
      initializing: false,
      tab: courseState.UPCOMING,
      searchKeyword: '',

      selectedCourse: 0,
      courseDetails
    };
  },

  async created() {
    await this.setCourseToLoad();
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    ...mapActions(OPPORTUNITY_BILLING_STORE, ['setCoursePrice', 'setCourseDetails']),
    ...mapActions(OPPORTUNITY_STORE, ['getCourses', 'setCurrentCourse']),

    async setCourseToLoad() {
      this.initializing = true;

      const filter = {
        search: this.searchKeyword
      };

      switch (this.tab) {
        case courseState.AVAILABLE:
          filter.isAvailable = 1;
          break;
        case courseState.UPCOMING:
          filter.upcoming = 1;
          break;
        case courseState.COMPLETED:
          filter.isCompleted = 1;
          break;
        default:
          break;
      }

      await this.loadCourses(filter);
    },

    async loadCourses(filter) {
      await this.getCourses(filter)
        .then((response) => {
          const { courses } = this;
          this.allCourseList = response.data.map((item) => {
            const {
              id, name, objective, availabilityLimit, userCourses
            } = item;
            const record = courses.find((x) => x.id === id);
            let hasSelectedDate = null;
            let selectedStartDate = '';
            let isUpcoming = false;

            if (record) {
              hasSelectedDate = record.hasSelectedDate;
              selectedStartDate = record.selectedStartDate;
            }

            if (filter.upcoming || filter.isCompleted) {
              const [userCourse] = userCourses;
              const { userCourseSchedules } = userCourse || [];

              if (userCourseSchedules.length) {
                hasSelectedDate = (userCourseSchedules.length);
                const [mainSchedule] = userCourseSchedules;
                selectedStartDate = mainSchedule.courseSchedule.startDate;
              }

              if (!filter.isCompleted) {
                isUpcoming = filter.upcoming;
              }
            }

            const course = {
              ...item,
              title: name,
              aims: objective,
              hasSelectedDate,
              slotLimit: availabilityLimit,
              isAvailable: filter.isAvailable,
              isCompleted: filter.isCompleted,
              selectedStartDate,
              isUpcoming,
              ...this.getCourseDetails(item.id),
            };

            // if (this.hasSavedDate(course)) {
            //   this.allHasSavedDateCourseList.push(course);
            // }
            // if (this.hasNoSavedDate(course)) {
            //   this.allHasNoSavedDateCourseList.push(course);
            // }
            return course;
          });
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error getting schedules.'
          });
        })
        .finally(() => {
        });
    },

    getCourseDetails(id) {
      const course = this.courseDetails.find((detail) => detail.id === id);
      return course;
    },

    hasNoSavedDate(course) {
      return course.isUpcoming && !course.hasSelectedDate;
    },

    hasSavedDate(course) {
      return course.isUpcoming && course.hasSelectedDate;
    },
  }
});
</script>
<style lang="scss" scoped>
.courses-opportunities {
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }

  &__wrapper.small.grid {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}
</style>
